<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="400"
  >
    <v-card>
      <v-card-title>
        <div class="text-center">
          <h5>Masukkan Tanggal</h5>
        </div>
        <v-row>
          <v-col class="pa-0 d-flex justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="closedDialog"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col class="pb-0 ">
              <v-row>
                <v-col>
                  <v-menu
                    ref="menuFromDate"
                    v-model="menuFromDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        class="caption"
                        placeholder="Tanggal"
                        prepend-inner-icon="mdi-calendar"
                        :value="dateStatus ? dayjs(dateStatus).format('DD-MM-YYYY') : null"
                        readonly
                        :rules="[v => !!v || $_strings.messages.validation.REQUIRED('tanggal')]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateStatus"
                      no-title
                      locale="id"
                      :min="invoiceDate"
                      @change="menuFromDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          small
          color="red white--text"
          @click="closedDialog"

        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          class="white--text"
          @click="createInvoiceFixedPrice"
        >
          {{$_strings.common.SAVE}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    invoiceDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dateStatus: '',
      dialog: false,
      menuFromDate: false,
      form: [],
    };
  },
  methods: {
    dayjs,
    createInvoiceFixedPrice() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.$dialog.notify.error('Mohon periksa kembali field yang wajib diisi');
        return;
      }
      this.dialog = false;
      this.$emit('updateStatusInvoice', this.dateStatus);
    },
    closedDialog() {
      this.dialog = false;
      this.$emit('fetchInvoice');
    },
  },
  created() {
    this.dateStatus = dayjs().format('YYYY-MM-DD');
  },
};
</script>

<style>
</style>
