<template>
  <v-container fluid>
    <v-row justify="space-between" class="mb-2" align="center">
      <v-col>
        <v-btn
          color="primary"
          elevation="1"
          @click="createInvoice()"
        >
          {{$_strings.invoice.ADD_TEXT}}
        </v-btn>
      </v-col>
      <v-col cols="12" md="3" sm="4">
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          :placeholder="$_strings.invoice.SEARCHNO"
          v-model="searchText"
          @keyup.enter="setFilter"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="setFilter">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-sheet>
      <v-row
        dense
        align="center"
        class="pa-5"
      >
        <v-col
          cols="6"
          sm="auto"
        >
          <span>{{ $_strings.invoice.DATE }}</span>
          <v-menu
            ref="menuFromDate"
            v-model="menuFromDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                hide-details
                outlined
                class="caption"
                placeholder="From Date"
                :value="startDate ? dayjs(startDate).format('DD-MM-YYYY') : null"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              no-title
              :max="endDate"
              @change="menuFromDate = false"
              locale="id"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="auto" class="mt-6 d-none d-sm-flex">-</v-col>
        <v-col cols="6" sm="auto">
          <v-menu
            ref="menuToDate"
            v-model="menuToDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                hide-details
                class="caption mt-6"
                outlined
                :value="endDate ? dayjs(endDate).format('DD-MM-YYYY') : null"
                placeholder="To Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :min="startDate"
              v-model="endDate"
              no-title
              @change="menuToDate = false"
              locale="id"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="auto" class="ml-sm-4">
          <span>Filter</span>
          <v-menu
            bottom
            :close-on-content-click="closeOnContentClick"
            width="300"
            offset-y
            z-index="1"
            allow-overflow
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details
                height="10"
                class="caption"
                prepend-inner-icon="mdi-filter"
                :placeholder="$_strings.invoice.FILTER"
                outlined
                dense
                readonly
                v-bind="attrs"
                @click="closeOnContentClick = false"
                v-on="on"
              ></v-text-field>
            </template>
            <v-list
              width="300"
              min-height="150"
              max-height="400"
              class="overflow-auto"
            >
              <section class="ml-4">
                <span class="caption font-weight-bold">{{ $_strings.invoice.FILTER_STATUS }}</span>
                <v-select
                  hide-details
                  chips
                  flat
                  :items="filterStatusBy"
                  :placeholder="$_strings.invoice.CHOOSE_STATUS"
                  :menu-props="{ bottom: true, offsetY: true }"
                  class="caption mb-4 mr-4"
                  dense
                  v-model="chipStatus"
                  @change="filterStatus()"
                  multiple
                  clearable
                >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      small
                      class="mb-2 font-weight-bold"
                      @click="select"
                      @click:close="removechipStatus(item)"
                    >
                      {{ item.text }}<br>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <span class="caption">{{item.text}}</span>
                  </template>
                </v-select>
                <span class="caption font-weight-bold">{{ $_strings.invoice.FILTER_TYPE }}</span>
                <v-select
                  hide-details
                  small-chips
                  flat
                  :items="filterTypeBy"
                  :placeholder="$_strings.invoice.CHOOSE_TYPE"
                  :menu-props="{ bottom: true, offsetY: true }"
                  class="caption mb-4 mr-4"
                  dense
                  v-model="chipsType"
                  @change="filterType"
                  multiple
                  clearable
                >
                  <template v-slot:selection="{ attrs, item,select, selected }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      small
                      class="mb-2 font-weight-bold"
                      @click="select"
                      @click:close="removeChipsType(item)"
                    >
                      {{ item.text }}<br>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <span class="caption">{{item.text}}</span>
                  </template>
                </v-select>
              </section>
              <section class="pl-2 pr-2">
                <v-btn
                  depressed
                  class="mt-2 white--text"
                  color="red"
                  block
                  @click="closeOnContentClick = true"
                >
                  {{$_strings.common.CLOSE}}
                </v-btn>
              </section>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        calculate-widths
        :items="itemsInvoice"
        :server-items-length="invoiceListTotalEntry"
        :options.sync="pagination"
        :item-class="itemRowIsEdit"
        @click:row="rowClicked"
        :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
      >
        <template v-slot:[`item.invoiceNo`]=items>
          <span>{{ items.item.invoiceNo }}</span>
          <br/>
          <span>{{ dateFormat(items.item.invoiceDate) }}</span>
        </template>
        <template v-slot:[`item.name3pl`]=items>
          <span>PT. Fastana Logistik</span>
          <br/>
          <span>{{ items.item.inputBy }}</span>
        </template>
        <template v-slot:[`item.periodStart`]=items>
          <span>{{ countMonths(items.item.periodStart, items.item.periodEnd) }} Bulan</span>
          <br/>
          <span class="size-12">{{ dateFormat(items.item.periodStart) }} - {{ dateFormat(items.item.periodEnd) }}</span>
        </template>
        <template v-slot:[`item.lokasiAsal`]=items>
          <span>{{ items.item.lokasiAsal ? items.item.lokasiAsal : '-' }}</span>
        </template>
        <template v-slot:[`item.lokasiTujuan`]=items>
          <span>{{ items.item.lokasiTujuan ? items.item.lokasiTujuan : '-' }}</span>
        </template>
        <template v-slot:[`item.totalAmount`]=items>
          <span>Rp. {{ formatAmount(items.item.totalAmount) }}</span>
          <br/>
          <span class="size-12" v-if="items.item.invoiceType === 'Pengiriman'">{{ items.item.totalShipment }} Pesanan</span>
          <span class="size-12" v-else>{{ items.item.totalShipment }} Biaya</span>
        </template>
        <template v-slot:[`item.invoiceType`]=items>
          <span>{{ items.item.invoiceType }}</span>
        </template>
        <template v-slot:[`item.createdBy`]=items>
          <span>{{ items.item.createdBy }}</span>
          <br/>
          <span class="size-12">{{ dateFormat(items.item.createdAt) }}</span>
        </template>
        <template v-slot:[`item.status`]="{item}">
          <v-select
            class="mt-3 pa-0 text-caption"
            style="width: 10rem"
            outlined
            dense
            :disabled="!userAccess.canUpdate"
            :items="itemsStatus(item)"
            item-value="text"
            item-text="text"
            v-model="item.status"
            @change="changeStatus(item, $event)"
            @click.stop.prevent>
          </v-select>
        </template>
        <template v-slot:[`item.actionColumn`]="{item}">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                x-small
                class="mr-2"
                color="primary"
                @click="exportPdf(item)"
                @click.stop.prevent>
                <v-icon>mdi-file-pdf-box</v-icon>
              </v-btn>
            </template>
            <span>File PDF</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.status === 'Proforma'"
                v-bind="attrs"
                v-on="on"
                x-small
                icon
                class="mr-2"
                color="primary"
                @click="exportExcel(item)"
                @click.stop.prevent>
                <v-icon> mdi-file-excel-box </v-icon>
              </v-btn>
            </template>
            <span>File XLS</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.status === 'Proforma'"
                v-bind="attrs"
                v-on="on"
                x-small
                icon
                class="mr-2"
                color="primary"
                @click="modalEmail(item)"
                @click.stop.prevent >
                <v-icon> mdi-email </v-icon>
              </v-btn>
            </template>
            <span>Email</span>
          </v-tooltip>
        </template>
        <template v-slot:[`footer.page-text`]="props">
          <span>
            {{$_strings.invoice.INVOICE_LIST}}
            <span v-if="itemsInvoice.length">
              {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
            </span>
          </span>
        </template>
      </v-data-table>
    </v-sheet>
    <v-row>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="700"
      >
        <v-card :loading="isLoadingSendEmail">
          <v-card-title>
            <span class="headtitle-email">Masukan Email Tujuan</span>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-toolbar-items>
                  <v-btn
                    icon
                    color="black"
                    dark
                    @click="closeModal"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-col>
            </v-row>
          </v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col>
                  <v-subheader>Email</v-subheader>
                </v-col>
                <v-col
                  cols="10"
                  class="mr-4"
                >
                  <v-autocomplete
                    outlined
                    dense
                    :items="getEmail"
                    :search-input.sync="searchEmail"
                    item-value="email"
                    item-text="email"
                    v-model="form.email"
                    multiple
                    chips
                    persistent-hint
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeEmail(item)"
                      >
                        {{ item.email }}<br>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <span>{{item.email}}</span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-btn
                  outlined
                  small
                  class="mr-2 ml-auto"
                  @click="closeModal"
                >
                  Batal
                </v-btn>
                <v-btn
                  depressed
                  small
                  color="primary"
                  class="white--text"
                  :loading="isLoadingSendEmail"
                  @click="submitEmail"
                >
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
    <DialogDateReceived
      ref="DialogDateReceived"
      @updateStatusInvoice="updateStatusInvoice"
      @fetchInvoice="fetchInvoice"
      :invoiceDate="periodStart"
    />
  </v-container>
</template>

<script>
import {
  dateFormat,
  handleSortBy,
  handlerPagination,
  defaultPagination,
  thousandSeparated,
} from '@/helper/commonHelpers';
import dayjs from 'dayjs';
import DialogDateReceived from '../InvoiceFixedPrice/Dialog/DateReceived.vue';

export default {
  name: 'InvoiceList',
  components: {
    DialogDateReceived,
  },
  created() {
    this.getEmailList();
  },
  data() {
    return {
      isLoadingSendEmail: false,
      isLoading: false,
      chipStatus: '',
      chipsType: [],
      filterStatusInv: '',
      filterTypeInv: '',
      items: [],
      dataInvoice: {},
      itemsInvoice: [],
      searchText: '',
      valid: false,
      invoiceListSize: 10,
      invoiceListTotalEntry: 0,
      form: {
        email: [],
      },
      headers: [
        {
          text: this.$_strings.invoice.INVOICE_NO,
          value: 'invoiceNo',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
        {
          text: this.$_strings.invoice.CUSTOMER_NAME,
          value: 'name3pl',
          class: 'white--text primary text-capitalize',
          width: '180px',
        },
        {
          text: this.$_strings.invoice.ORDER_PERIODE,
          value: 'periodStart',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.order.ORIGIN,
          value: 'lokasiAsal',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'lokasiTujuan',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.invoice.INVOICE_TOTAL,
          value: 'totalAmount',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
        {
          text: this.$_strings.invoice.INVOICE_TYPE,
          value: 'invoiceType',
          class: 'white--text primary text-capitalize',
          width: '180px',
        },
        {
          text: this.$_strings.invoice.CREATED_BY,
          value: 'createdBy',
          class: 'white--text primary text-capitalize',
          width: '180px',
        },
        {
          text: this.$_strings.invoice.STATUS,
          value: 'status',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
        {
          text: this.$_strings.invoice.ACTION,
          value: 'actionColumn',
          align: 'left',
          class: 'white--text primary text-capitalize',
          sortable: false,
          width: '8vw',
        },
      ],
      filterStatusBy: this.$_strings.invoice.FILTER_STATUS_LIST,
      filterStatusOke: [
        {
          text: 'Proforma',
          value: 'PROFORMA',
        },
        {
          text: 'Belum Dicetak',
          value: 'BELUM_DICETAK',
        },
        {
          text: 'Sudah Dicetak',
          value: 'SUDAH_DICETAK',
        },
        {
          text: 'Sudah Dikirim',
          value: 'SUDAH_DIKIRIM',
        },
        {
          text: 'Sudah Diterima',
          value: 'SUDAH_DITERIMA',
        },
        {
          text: 'Sudah Dibayar',
          value: 'SUDAH_DIBAYAR',
        },

      ],
      filterTypeBy: this.$_strings.invoice.FILTER_TYPE_LIST,
      expanded: [],
      pagination: defaultPagination(),
      menuToDate: false,
      menuFromDate: false,
      startDate: this.$route.query.startDate || dayjs().subtract('7', 'days').format('YYYY-MM-DD'),
      endDate: this.$route.query.endDate || dayjs().format('YYYY-MM-DD'),
      totalItems: 0,
      subOrderLoading: false,
      dialog: false,
      getEmail: [],
      searchEmail: null,
      closeOnContentClick: false,
      statusChange: '',
      invoiceId: '',
      invoiceStatusId: '',
      periodStart: '',
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchInvoice();
      },
      deep: true,
    },
    searchEmail(val) {
      if (val === null) {
        this.$_services.invoice.emailList()
          .then((res) => {
            this.getEmail = res.data.contents;
          });
      } else {
        this.$_services.invoice.emailSearchList(val)
          .then((res) => {
            this.getEmail = res.data.contents;
          });
      }
    },
    startDate(newVal) {
      const { startDate } = this.$route.query;
      if (JSON.stringify(startDate) !== JSON.stringify(newVal)) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            startDate: newVal,
          },
        });
      }
      this.setFilter();
    },
    endDate(newVal) {
      const { endDate } = this.$route.query;
      if (JSON.stringify(endDate) !== JSON.stringify(newVal)) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            endDate: newVal,
          },
        });
      }
      this.setFilter();
    },
  },
  methods: {
    dateFormat,
    dayjs,
    setFilter() {
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
        return;
      }
      this.fetchInvoice();
    },
    itemRowIsEdit(item) {
      if (item.status === 'Proforma') {
        return 'pointer';
      }
    },
    rowClicked(e, { item }) {
      if (item.status === 'Proforma') {
        this.$router.push(`/main/invoice-transporter/edit/${item.id}`);
      }
    },
    formatAmount(amount) {
      return thousandSeparated(amount);
    },
    createInvoice() {
      this.$router.push('/main/invoice-transporter/create');
    },
    filterStatus() {
      this.filterStatusInv = this.chipStatus;
      this.setFilter();
    },
    filterType() {
      this.filterTypeInv = this.chipsType;
      this.setFilter();
    },
    removeChipsType(item) {
      const idChips = this.chipsType.findIndex((e) => e === item.value);
      this.chipsType.splice(idChips, 1);
      this.chipsType = [...this.chipsType];
      this.filterType(this.chipsType);
    },
    removechipStatus(item) {
      const idChips = this.chipStatus.findIndex((e) => e === item.value);
      this.chipStatus.splice(idChips, 1);
      this.chipStatus = [...this.chipStatus];
      this.filterStatus();
    },
    removeEmail(item) {
      this.form.email.splice(this.form.email.indexOf(item), 1);
      this.form.email = [...this.form.email];
    },
    fetchInvoice() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        name: this.searchText,
        sort: handleSortBy({ sortBy, sortDesc }),
        dateFrom: this.startDate,
        dateTo: this.endDate,
        statusInvoice: this.filterStatusInv,
        typeInvoice: this.filterTypeInv,
      };
      this.isLoading = true;
      this.$_services.invoice.fetchInvoice(filters)
        .then((result) => {
          this.itemsInvoice = result.data.contents;
          this.invoiceListTotalEntry = result.data.totalData;
        }).finally((e) => {
          this.isLoading = false;
        });
    },
    getEmailList() {
      this.$_services.invoice.emailList()
        .then((res) => {
          this.getEmail = res.data.contents;
        }).finally(() => {
          this.$root.$loading.hide();
        });
    },
    modalEmail(item) {
      this.dialog = true;
      this.invoiceId = item.id;
    },
    closeModal() {
      this.dialog = false;
      this.invoiceId = '';
    },
    itemsStatus(stat) {
      const ind = this.filterStatusBy.findIndex((e) => e.text === stat.status);
      const data = [];
      this.filterStatusBy.forEach((item, index) => {
        if (index === ind || index === ind + 1) {
          data.push({
            ...item,
          });
          return;
        }
        data.push({
          ...item,
          disabled: true,
        });
      });
      return data;
    },
    changeStatus(itemStatus, event) {
      this.periodStart = dayjs(itemStatus.periodStart).format('YYYY-MM-DD');
      this.statusChange = this.filterStatusBy.find((i) => i.text === event).value;
      this.invoiceStatusId = itemStatus.id;
      if (event === 'Sudah diterima' || event === 'Sudah dibayar') {
        this.$refs.DialogDateReceived.dialog = true;
        return;
      }
      this.updateStatusInvoice();
    },
    updateStatusInvoice(date = '') {
      this.$root.$loading.show();
      this.$_services.invoice.changeStatusInvoice(this.invoiceStatusId, this.statusChange, date)
        .then((res) => {
          this.$dialog.notify.success(this.$_strings.invoice.EDIT_STATUS_MESSAGE_TEXT);
        }).finally(() => {
          this.$root.$loading.hide();
          this.fetchInvoice();
        });
    },
    countMonths(start, end) {
      const sDate = new Date(start).getMonth();
      const eDate = new Date(end).getMonth();
      const sYear = new Date(start).getFullYear() * 12;
      const eYear = new Date(end).getFullYear() * 12;
      const difference = (eYear + eDate) - (sYear + sDate);
      if (difference === 0) {
        return 1;
      }
      return difference;
    },
    exportPdf(item) {
      if (item.invoiceNo) {
        this.$_services.invoice.exportPdfInvoice(item.id).then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          const { invoiceNo } = item;
          const invoiceLink = invoiceNo.replace(/\//g, '_');
          link.download = `invoice-${invoiceLink}.pdf`;
          link.click();
          URL.revokeObjectURL(link.href);
        }).catch(console.error);
        return;
      }
      const parameter = {
        invoiceId: item.id,
        invoiceType: 'TRANSPORTER',
        exportFile: 'PDF',
      };
      this.$_services.invoice.exportPdfInvoiceProforma(parameter).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'invoice-proforma.pdf';
        link.click();
        URL.revokeObjectURL(link.href);
      }).catch(console.error);
    },
    exportExcel(item) {
      const parameter = {
        invoiceId: item.id,
        invoiceType: 'TRANSPORTER',
        exportFile: 'EXCEL',
      };
      this.$_services.invoice.exportPdfInvoiceProforma(parameter).then((response) => {
        const blob = new Blob([response.data], { type: 'application/xls' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'invoice-proforma.xls';
        link.click();
        URL.revokeObjectURL(link.href);
      }).catch(console.error);
    },
    submitEmail() {
      if (this.form.email.length < 1) {
        this.$dialog.notify.error('Mohon periksa kembali field yang kosong');
        return;
      }
      this.isLoadingSendEmail = true;
      this.$_services.invoice.sendEmail(this.form.email, this.invoiceId)
        .then((res) => {
          this.$dialog.notify.success('Berhasil mengirimkan proforma ke email.');
        }).finally(() => {
          this.isLoadingSendEmail = false;
          this.dialog = false;
          this.$refs.form.reset();
          this.fetchInvoice();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-chip.v-size--default {
    font-size: 12px;
    margin-top: 3px;
}
.size-12 {
  font-size: 12px;
}
</style>

<style>
.pointer {
  cursor: pointer;
  }
</style>
